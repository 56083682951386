import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as miCasaStyles from "../styles/micasa.module.css"
import * as noMasStyles from "../styles/nomas.module.css"
import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"
import questionsVideo from "../assets/nomas/questions2.mp4"
import textingVideo from "../assets/nomas/texting2.mp4"
import signingVideo from "../assets/nomas/signing2.mp4"
import awarenessImg from "../assets/nomas/awareness.png"
import legaleseImg from "../assets/nomas/legalese.png"

import dot1 from "../assets/nomas/1dot.png"
import dot3 from "../assets/nomas/3dots.png"
import dot5 from "../assets/nomas/5dots.png"
import dot16 from "../assets/nomas/16dots.png"


const NoMas = () => (

<main>
    <div id={miCasaStyles.topPageContainer}>
        <Seo title="No more evictions"/>
        <Header home={false}/>
        <section id={miCasaStyles.heroContainer}>
            <div id={miCasaStyles.heroText}>
                No More<br/>
                Evictions
                <div id={miCasaStyles.heroSubtext}>
                    Building an online tool that allows immigrant families to easily file for the CDC moratorium on evictions. In partnership with the Atlanta city government
                </div>
            </div>
            <StaticImage 
                src="../assets/nomas/heroImg.png"
                alt="Illustration of a home protected by the CDC moratorium on evictions"
                className={noMasStyles.heroImage}
                quality={100}
                loading="eager"
                width={490}
                placeholder="tracedSVG"
                />
            <div id={miCasaStyles.heroSubtextMobile}>
                Building an online tool that allows immigrant families to easily file for the CDC moratorium on evictions. In partnership with the Atlanta city government
            </div>
        </section>
    </div>

    <section id={miCasaStyles.overviewOutterContainer}>
            <div id={miCasaStyles.overviewInnerContainer}>
                        <div class = {miCasaStyles.sectionHeader}>
                            Overview
                        </div>
                        <div id={miCasaStyles.ovSubheadersContainer}>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={noMasStyles.ovSubheader}>
                                    Result
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    A mobile-first web tool that<br/> texts users a pre-filled<br/> Docusign CDC declaration form
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={noMasStyles.ovSubheader}>
                                    My role
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    Back-end web developer
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={noMasStyles.ovSubheader}>
                                    The team
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                Hannan Abdi, Santiago Arconada,<br/> Olivia Hughes, Rhea Laroya
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem} id={miCasaStyles.ovSubheaderItemNoMargin}>
                                <div class={noMasStyles.ovSubheader}>
                                    Tools
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                Flask, Twilio,<br/> Python Anywhere,<br/> Docusign API, Javascript

                                </div>
                            </div>
                        </div>

                        <div id={miCasaStyles.timelineContainer}>
                            <div class={noMasStyles.ovSubheader}>
                                        Timeline
                            </div>
                            <div class={miCasaStyles.ovSubheaderText}>
                                Aug 2020 - Dec 2020
                            </div>
                            <StaticImage 
                                src="../assets/nomas/timeline.png"
                                alt="Timeline of project work"
                                className={miCasaStyles.timelineImg}
                                quality={100}
                                width={1067}
                                loading={"eager"}
                            />
                        </div>

                        <div id={miCasaStyles.clientContainer}>
                            <div id={miCasaStyles.clientTextContainer}>
                                <div class={noMasStyles.ovSubheader}>
                                    Client
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    <a href="https://www.welcomingatlanta.com/" target="_blank" rel="noreferrer">The Atlanta Mayor’s Office of Immigrant Affairs</a> <br/> <span id={miCasaStyles.clientSubtext} >(Known as Welcoming Atlanta)</span>
                                </div>
                                <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.clientBody}>
                                    Welcoming Atlanta provides direct service work to immigrants in Atlanta, offering personal guidance and support, running food drives, connecting residents with legal and victims counseling, among a host of other assistance measures. <span class={noMasStyles.greenText}>Their constituency is largely Latinx, low-income folks who may or may not be documented.</span>
                                </div>
                            </div>
                            <div id={miCasaStyles.watlWrapper}>
                                <StaticImage 
                                    src="../assets/micasa/WATLlogo.png"
                                    alt="Logo of Welcoming Atlanta"
                                    class={miCasaStyles.watlLogo}
                                    placeholder={"blurred"}
                                    quality={100}
                                />
                            </div>
                        </div>

                        <div class={miCasaStyles.sectionContainer}>
                            <div class={miCasaStyles.pageSubheader}>
                                Problem
                            </div>
                            <div class={miCasaStyles.problemContent}>
                                <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.problemBody}>
                                Months into the pandemic, the Mayor’s Office of Immigrant Affairs (known as Welcoming Atlanta) noticed that their constituents were increasingly reporting cases of eviction. Most of these evictions were happening illegally, since a majority of the tenants should have been protected by the <a href="https://www.cdc.gov/coronavirus/2019-ncov/covid-eviction-declaration.html" target="_blank" rel="noreferrer">CDC moratorium on evictions</a>, which halted all residential evictions due to nonpayment during COVID.
                                <br/><br/>
                                As the client of our Master’s project, <span class={noMasStyles.greenText}>Welcoming Atlanta asked us to create a solution that would help their residents discover if they are eligible for the CDC’s protection and file the letter to prevent eviction.</span>
                                </div>
                                <StaticImage 
                                        src="../assets/nomas/statistic.png"
                                        alt="Illustration of concerned woman"
                                        className={noMasStyles.problemImg}
                                        placeholder={"tracedSVG"}
                                        quality={100}
                                        width={240}
                                />
                            </div>
                        </div>
                        
                        <div class={miCasaStyles.sectionContainer}>
                            <div class={miCasaStyles.pageSubheader}>
                                Solution
                            </div>
                            <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.solutionBody}>
                                We designed and built NoMasDeslojos.com (No More Evictions in English), a mobile website in Spanish and English that uses plain language to help users discover if they are eligible for the CDC’s moratorium on evictions, then texts them a link to a pre-filled Docusign CDC letter that they can file with their landlords.                                <br/><br/>
                                <span class={noMasStyles.greenText}>Since its launch in December 2020, nearly 300 text messages have been sent to community members with a linked CDC declaration.</span>
                                <br/><br/>
                            </div>
                            <div id={miCasaStyles.award} >
                                <div id={miCasaStyles.star}>
                                    <StaticImage 
                                            src="../assets/micasa/star.png"
                                            alt="Star"
                                            class={miCasaStyles.star}
                                            placeholder={"blurred"}
                                            quality={100}
                                    />
                                </div>
                                <div id={miCasaStyles.awardText}>
                                    This project was awarded the best team Master’s project in the MS HCI program at Georgia Tech.
                                </div>
                            </div>
                        </div>

                        <div class={miCasaStyles.gifBlock} id={miCasaStyles.firstGifBlock}>
                            <div class={noMasStyles.gifText}>
                                Discover your eligibility
                                <div class={miCasaStyles.gifBody}>
                                    Being eligible for the CDC’s protection requires one to meet specific criteria. No Mas Desalojos asks the user a few questions and evaluates their eligibility insantly.
                                </div>
                            </div>
                            <div class={miCasaStyles.overviewGif}>
                                <video autoPlay loop muted playsInline src={questionsVideo}></video>
                            </div>
                        </div>

                        <div class={miCasaStyles.gifBlock}>
                            <div class={`${miCasaStyles.overviewGif} ${miCasaStyles.orderTwo}`}>
                                <video autoPlay loop muted playsInline src={textingVideo}></video>
                            </div>
                            <div class={noMasStyles.gifText}>
                                Text the letter to your phone
                                <div class={miCasaStyles.gifBody}>
                                    After completing the eligibility questionnaire, the user can have a link to the CDC declaration form texted to their phone in seconds via the Twilio API.
                                </div>
                            </div>
                        </div>

                        <div class={miCasaStyles.gifBlock}>
                            <div class={noMasStyles.gifText}>
                                Sign and deliver
                                <div class={miCasaStyles.gifBody}>
                                    The texted Bitly link takes the user to a pre-filled Docusign CDC declaration form. The user simply taps “Sign” and has a legally binding CDC letter that they can send to their landlords and prevent eviction.
                                </div>
                            </div>
                            <div class={miCasaStyles.overviewGif}>
                                <video autoPlay loop muted playsInline src={signingVideo}></video>
                            </div>
                        </div>

                        <div class={miCasaStyles.sectionContainer}>
                            <div class={miCasaStyles.pageSubheader}>
                                Link to website
                            </div>
                            <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.linkBody}>
                                The website can be accessed at <a href="https://www.nomasdesalojos.com/" target="_blank" rel="noreferrer">NoMasDesalojos.com</a>. Keep in mind that the website is intended to be used on mobile devices (since this is how community members are accessing it). 
                                <br/><br/>
                                We ask that you don’t use the tool unless you need it, since the texting and Docusign functionalities are paid services.
                            </div>
                        </div>

                            <div id={miCasaStyles.contributionContent}>
                            <div>
                                <div class={miCasaStyles.sectionContainer} id={miCasaStyles.contributionContainer} >
                                <div class={miCasaStyles.pageSubheader}>
                                    My contribution
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    <span class={noMasStyles.greenText}>I was the backend developer on the project</span>, writing the server code in Python (Flask), integrating it with the Twilio, Bitly, and Docusign APIs, handling integration with the frontend (AJAX), and deploying the website on PythonAnywhere. I also played a supporting role in the research work, attending field observations, interviews, and the focus group.
                                </div>
                                </div>
                            </div>
                            <div>
                                <StaticImage 
                                        src="../assets/nomas/volunteering.png"
                                        alt="Image of teammate and myself volunteering"
                                        className={noMasStyles.volunteeringImg}
                                        placeholder={"blurred"}
                                        quality={100}
                                        width={387}
                                />
                                <div class={miCasaStyles.imageCaption2} style={{maxWidth: '300px', marginLeft: '9.5rem', marginTop:".5rem"}} id={noMasStyles.volunteeringCaption}>
                                    Santiago (left) and me at a Welcoming Atlanta food distribution event
                                </div>
                            </div>
                        </div>

            </div> {/* End of overview content div */}
        </section>

        <section id={miCasaStyles.outterDetailsContainer}>
            <div id={miCasaStyles.innerDetailsContainer}>
                <div class = {miCasaStyles.sectionHeader}>
                    Details
                </div>
                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                        Research
                </div>
                <div class={miCasaStyles.bodyText}>
                    Before we dove into a technical solution to the illegal eviction problem, we first worked to better understand what the community was going through, and to build relationships with tenants and Welcoming Atlanta staff.
                </div>

                <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop4}`} style={{marginBottom: "2rem"}}>
                    Research activities conducted
                </div>

                <div id={miCasaStyles.participantsOutter}>
                    <div class={miCasaStyles.participantsContainer}>
                        <div class={miCasaStyles.participantsItem}>
                            <div class={miCasaStyles.bodyText} style={{fontWeight: "bold", marginBottom: "4px"}}>
                                16
                            </div>
                            <div class={miCasaStyles.bodyText}>
                                Field observations
                            </div>
                            <img
                                src={dot16}
                                alt="16 dots"
                                width={66}
                                height={66}
                                className={noMasStyles.dotsImg}
                            />  
                        </div>

                        <div class={miCasaStyles.participantsItem}>
                            <div class={miCasaStyles.bodyText} style={{fontWeight: "bold", marginBottom: "4px"}}>
                                3
                            </div>
                            <div class={miCasaStyles.bodyText}>
                                Staff interviews
                            </div>
                            <img
                                src={dot3}
                                alt="3 dots"
                                className={noMasStyles.dotsImg}
                                width={66}
                                height={66}
                            />  
                        </div>
                    </div>

                    <div class={miCasaStyles.participantsContainer}>

                    <div class={miCasaStyles.participantsItem}>
                            <div class={miCasaStyles.bodyText} style={{fontWeight: "bold", marginBottom: "4px"}}>
                                5
                            </div>
                            <div class={miCasaStyles.bodyText}>
                                Expert interviews
                            </div>
                            <img
                                src={dot5}
                                alt="5 dots"
                                className={noMasStyles.dotsImg}
                                width={66}
                                height={66}
                            />  
                        </div>

                        <div class={miCasaStyles.participantsItem} id={miCasaStyles.participantsLast}>
                            <div class={miCasaStyles.bodyText} style={{fontWeight: "bold", marginBottom: "4px"}}>
                                1
                            </div>
                            <div class={miCasaStyles.bodyText}>
                                Focus group
                            </div>
                            <img
                                src={dot1}
                                alt="1 dots"
                                className={noMasStyles.dotsImg}
                                width={66}
                                height={66}
                            />  
                        </div>
                    </div>
                </div>

                <div class={`${miCasaStyles.bodyText} ${noMasStyles.marginTop6_3}`}>
                    <span class={noMasStyles.greenText}>Since the solution that we were meant to build would interface primarily with the community, we focused heavily on field observation research.</span> This included attending volunteer events within the affected communities and observing community navigator meetings (community navigators are staff members who live and work in the areas Welcoming Atlanta serves).
                </div>

                <div class={noMasStyles.indentedFlex}>
                    <div class={miCasaStyles.indentedContainer}>
                        <div class={`${miCasaStyles.indentedInner} ${miCasaStyles.marginTop1}`}>
                            <div class={`${noMasStyles.bar} ${noMasStyles.green1}`}/>
                            <div class={miCasaStyles.detailsHeader}>
                                Results
                            </div>
                            <div class={miCasaStyles.bodyText}>
                                <span class={noMasStyles.greenText}> One of the most valuable things we gained from our research was visibility and trust.</span> Community members and Welcoming Atlanta staff knew us by name, and could see our dedication to their work through our continued presence.
                                <br/><br/>
                                We also learned a lot about the community and why the CDC moratorium was largely not being effective in preventing evictions. 
                            </div>
                        </div>
                    </div>
                    <div>
                        <StaticImage 
                            src="../assets/nomas/resultsImg.png"
                            alt="1 dots"
                            className={noMasStyles.resultsImg}
                            placeholder={"tracedSVG"}
                            width={180}
                        />  
                    </div>
                </div>

                <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop6}`} style={{marginBottom: "2rem"}}>
                    Why the CDC’s ban wasn’t working
                </div>

                <div id={noMasStyles.resultsContainer}>
                    <div>
                        <div class={noMasStyles.resultsItem}>
                            <div class={noMasStyles.resultsTop}>
                                <div class={noMasStyles.greenBorderNum}>
                                    #1
                                </div>
                                <div class={noMasStyles.headerBold}>
                                    Lack of awareness
                                </div>
                                <img src={awarenessImg} alt="Crossed out eye" class={noMasStyles.awarenessImg}/>
                            </div>
                            <div class={miCasaStyles.bodyText} style={{maxWidth: "30rem", marginTop: "2rem"}}>
                                First and foremost, many community members were not aware of the moratorium, according to Welcoming Atlanta. While the organization has attempted to raise consciousness of the order, reaching people and getting people to understand it has remained a challenge.
                            </div>
                        </div>


                        <div class={noMasStyles.resultsItem}>
                            <div class={noMasStyles.resultsTop}>
                                <div class={noMasStyles.greenBorderNum}>
                                    #2
                                </div>
                                <div class={noMasStyles.headerBold}>
                                    Inaccessible legalese
                                </div>
                                <img src={legaleseImg} alt="Legal documents" class={noMasStyles.legaleseImg}/>
                            </div>
                            <div class={miCasaStyles.bodyText} style={{maxWidth: "30rem", marginTop: "2rem"}}>
                                While the CDC’s ban sounds simple enough, the process to wield it for protection is not. For starters, if you are interested in filing the CDC letter, you must first discover if you are eligible or not. There are currently five points of criteria that you must meet, some of which are pretty difficult to understand; for example, here is the second criteria:
                            </div>

                            <div id={noMasStyles.smBlockQuote} class={miCasaStyles.marginTop2}>
                                <div class={miCasaStyles.indentedInner}>
                                    <div class={miCasaStyles.quoteBar} style={{width:"6px"}}/>
                                    <div class={noMasStyles.CDCQuoteText}>
                                        “The individual either (i) earned no more than $99,000 (or $198,000 if filing jointly) in Calendar Year 2020, or expects to earn no more than $99,000 in annual income for Calendar Year 2021   (or no more than $198,000 if filing a joint tax return),4 (ii) was not required to report any income in 2020 to the U.S. Internal Revenue Service, or (iii) received an Economic Impact Payment (stimulus check).”
                                    </div>
                                </div>
                        </div>

                        <div class={miCasaStyles.bodyText} style={{maxWidth: "30rem", marginTop: "2rem"}}>
                            You can image how difficult it might be for someone to make sense of this if English was not their first language.
                        </div>

                        </div>
                    </div>
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={`${miCasaStyles.indentedInner} ${noMasStyles.marginTop2_0}`}>
                        <div class={`${noMasStyles.bar} ${noMasStyles.green2}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            System requirements
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            Based on the user needs and tasks we found through our research and our conversations with Welcoming Atlanta, we were able to rapidly generate a list of design requirements for the solution. Due to the urgency of the demand (the CDC moratorium was set to lapse within a few months and evictions were increasing), the design process was accelerated as much as possible.
                        </div>
                    </div>
                </div>

                <div id={noMasStyles.sysReqs} class={miCasaStyles.bodyText}>
                    <div class={noMasStyles.sysReqItem}>
                        <div class={noMasStyles.greenFillNum}>1</div> 
                        <div class={noMasStyles.sysReqsText}>Application must be in conversational, accessible Spanish</div>
                    </div>
                    <div class={noMasStyles.sysReqItem}>
                        <div class={noMasStyles.greenFillNum}>2</div> 
                        <div class={noMasStyles.sysReqsText}>Application must be accessible via a phone</div>
                    </div>
                    <div class={noMasStyles.sysReqItem}>
                        <div class={noMasStyles.greenFillNum}>3</div> 
                        <div class={noMasStyles.sysReqsText}>Application should allow user to reach out for support</div>
                    </div>
                    <div class={noMasStyles.sysReqItem}>
                        <div class={noMasStyles.greenFillNum}>4</div> 
                        <div class={noMasStyles.sysReqsText}>Application should reduce fear of retaliation from landlords or the government</div>
                    </div>
                    <div class={noMasStyles.sysReqItem}>
                        <div class={noMasStyles.greenFillNum}>5</div> 
                        <div class={noMasStyles.sysReqsText}>Application should allow user to sign CDC letter online</div>
                    </div>
                    <div class={noMasStyles.sysReqItem}>
                        <div class={noMasStyles.greenFillNum}>6</div> 
                        <div class={noMasStyles.sysReqsText}>Application should text the CDC letter directly to user’s phone (avoid email, which many users do not have)</div>
                    </div>
                </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                        Design
                </div>
                <div class={miCasaStyles.bodyText}>
                    <span class={noMasStyles.greenText}>Due to a spike in illegal evictions within the community, urgency took top priority in the design and development of the application.</span>  Instead of completing a full design in Figma, getting feedback, and then starting development, we did design and dev concurrently.
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${noMasStyles.bar} ${noMasStyles.green3}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            Design mockups
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            To ensure that development could begin as soon as possible, we immediately created high fidelity mockups in Figma. Since community members almost exclusively have access to phones (and not desktops), we designed for mobile. Below are a few screens from the initial mocks:
                        </div>
                    </div>
                </div>

                <div class={noMasStyles.mocksContainer}>
                    <div class={noMasStyles.mocksImgWrapper}>
                        <StaticImage 
                                src="../assets/nomas/mock1.png"
                                alt="Screen of application"
                                className={noMasStyles.mockImg}
                                placeholder={"blurred"}
                                quality={100}
                                width={220}
                        />
                    </div>

                    <div class={noMasStyles.mocksImgWrapper}>
                        <StaticImage 
                                src="../assets/nomas/mock2.png"
                                alt="Screen of application"
                                className={noMasStyles.mockImg}
                                placeholder={"blurred"}
                                quality={100}
                                width={220}
                        />
                    </div>


                    <div class={noMasStyles.mocksImgWrapper}>
                        <StaticImage 
                                src="../assets/nomas/mock3.png"
                                alt="Screen of application"
                                className={noMasStyles.mockImg}
                                placeholder={"blurred"}
                                quality={100}
                                width={220}
                        />
                    </div>

                    <div class={noMasStyles.mocksImgWrapper}>
                        <StaticImage 
                                src="../assets/nomas/mock4.png"
                                alt="Screen of application"
                                className={noMasStyles.mockImg}
                                placeholder={"blurred"}
                                quality={100}
                                width={220}
                        />
                    </div>
                </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.marginTop9}`}>
                    Technical implementation
                </div>
                <div class={miCasaStyles.bodyText}>
                    With the design requirements laid out and some initial mockups done, we architected a system that would be able to meet our needs.
                </div>

                <div class={`${miCasaStyles.figureTitle} ${noMasStyles.marginTop6_3}`} style={{marginBottom: "2rem"}}>
                    The tech stack
                </div>

                <div id={noMasStyles.techOutter}>
                    <div class={noMasStyles.techInner}>
                        <StaticImage 
                            src="../assets/nomas/jspsych.png"
                            alt="Jspsych logo"
                            className={noMasStyles.jspsychImg}
                            placeholder={"blurred"}
                            quality={100}
                            width={225}
                        />
                        <div class={`${miCasaStyles.bodyText} ${noMasStyles.techText}`}>
                            On the frontend we used a small javascript library called jspsych. It was perfect for quickly creating a system that asks the user a series of questions, and stores their responses in a data object that we could send to the backend for processing.
                        </div>
                    </div>

                    <div class={noMasStyles.techInner}>
                        <StaticImage 
                            src="../assets/nomas/flask.png"
                            alt="Flask logo"
                            className={noMasStyles.flaskImg}
                            placeholder={"blurred"}
                            quality={100}
                            width={225}
                        />
                        <div class={`${miCasaStyles.bodyText} ${noMasStyles.techText}`}>
                            On the backend, Flask (a Python micro web framework) handles all of the server code, including routing, data processing and sanitation, and API calls.
                        </div>
                    </div>

                    <div class={noMasStyles.techInner}>
                        <StaticImage 
                            src="../assets/nomas/docusign.png"
                            alt="Docusign logo"
                            className={noMasStyles.docusignImg}
                            placeholder={"blurred"}
                            quality={100}
                            width={225}
                            height={49.32}
                        />
                        <div class={`${miCasaStyles.bodyText} ${noMasStyles.techText}`}>
                            Once the user submits a request for the CDC form, Flask generates a DocuSign link, embedding their information as URL parameters used to auto-populate the form. It then shortens this link through usage of the Bitly API.
                        </div>
                    </div>

                    <div class={noMasStyles.techInner}>
                        <StaticImage 
                            src="../assets/nomas/twilio.png"
                            alt="Twilio logo"
                            className={noMasStyles.twilioImg}
                            placeholder={"blurred"}
                            quality={100}
                            width={225}
                        />
                        <div class={`${miCasaStyles.bodyText} ${noMasStyles.techText}`}>
                            Finally, Flask calls the twilio API (a cloud platform for programmatically placing texts or calls) to text the user the link to the form – all within a few seconds.
                        </div>
                    </div>
                </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.marginTop9}`}>
                    Deployment and impact   
                </div>
                <div class={miCasaStyles.bodyText}>
                    We launched NoMasDesalojos.com in December 2020, providing Welcoming Atlanta with training on how to use the tool, and helping them get it out to the community for use.
                </div>

                <div class={`${miCasaStyles.figureTitle} ${noMasStyles.marginTop6_3}`} style={{marginBottom: "2rem"}}>
                    Number of texts sent per month
                </div>

                <div class={miCasaStyles.flexBox}>
                    <StaticImage 
                        src="../assets/nomas/impactGraph.png"
                        alt="Graph showing number of texts sent each month via the application"
                        className={noMasStyles.impactImg}
                        placeholder={"blurred"}
                        quality={100}
                        width={551}
                    />
                </div>

                <div class={miCasaStyles.bodyText}>
                    At launch, we saw modest usage of the website. As you can see in the graph above the number of texts in the first few months of deployment varied between ~10 to ~30 texts (to unique phone numbers).
                    <br/><br/>
                    <span class={noMasStyles.greenText}>However, in March, we saw a huge uptick in the number of texts sent.</span> This was due to two  reasons: 1) Welcoming Atlanta did another wave of outreach around the application, and 2) a round of rental assistance (financial relief) that was promised fell through, leaving a lot of folks at major risk of eviction in the community.
                    <br/><br/>
                    At time of writing, the CDC moratorium is still in effect, and the website continues to be used and maintained.
                </div>

                <div id={miCasaStyles.buttonsContainer} class={`${miCasaStyles.flexBox} ${miCasaStyles.marginTop6}`} style={{paddingBottom: "2rem"}}>
                    <Link to="/" class={miCasaStyles.linkButton}>
                        <div id={noMasStyles.takeMeHome} class={miCasaStyles.botButton}>
                            Take me home
                        </div>
                    </Link>
                    <Link to={`/no-more-evictions#${miCasaStyles.topPageContainer}`}>
                        <div id={noMasStyles.backTop} class={miCasaStyles.botButton}>
                            Back to top
                        </div>
                    </Link>
                </div>

            </div> {/* Inner details ends here */}
        </section>

    <Footer/>
</main>
)

export default NoMas