// extracted by mini-css-extract-plugin
export var CDCQuoteText = "nomas-module--CDCQuoteText--XEYww";
export var awarenessImg = "nomas-module--awarenessImg--EtCoz";
export var backTop = "nomas-module--backTop--Mwz9q";
export var bar = "nomas-module--bar--QtVI-";
export var docusignImg = "nomas-module--docusignImg--BfCvZ";
export var dotsImg = "nomas-module--dotsImg--V1lL+";
export var flaskImg = "nomas-module--flaskImg--XhfrM";
export var gifText = "nomas-module--gifText--cMclK";
export var green1 = "nomas-module--green1--fSRs3";
export var green2 = "nomas-module--green2--x7egz";
export var green3 = "nomas-module--green3--m10o9";
export var greenBorderNum = "nomas-module--greenBorderNum--LTQZi";
export var greenFillNum = "nomas-module--greenFillNum--0A-uD";
export var greenText = "nomas-module--greenText--QNl3W";
export var headerBold = "nomas-module--headerBold--7bT5g";
export var heroImage = "nomas-module--heroImage--S3Sph";
export var impactImg = "nomas-module--impactImg--PMDq2";
export var indentedFlex = "nomas-module--indentedFlex--1Ix9o";
export var jspsychImg = "nomas-module--jspsychImg--1rk+u";
export var legaleseImg = "nomas-module--legaleseImg--u5QG2";
export var marginTop2_0 = "nomas-module--marginTop2_0--1N2Gr";
export var marginTop4_2 = "nomas-module--marginTop4_2--Zd4t6";
export var marginTop6_3 = "nomas-module--marginTop6_3--Csr4t";
export var mockImg = "nomas-module--mockImg--XViB2";
export var mocksContainer = "nomas-module--mocksContainer--JsED-";
export var mocksImgWrapper = "nomas-module--mocksImgWrapper---y6ys";
export var ovSubheader = "nomas-module--ovSubheader--IBLKU";
export var problemImg = "nomas-module--problemImg--dikSp";
export var resultsContainer = "nomas-module--resultsContainer--llT0j";
export var resultsImg = "nomas-module--resultsImg--qTAnl";
export var resultsItem = "nomas-module--resultsItem--NZ69C";
export var resultsTop = "nomas-module--resultsTop--BZvoZ";
export var smBlockQuote = "nomas-module--smBlockQuote--v392s";
export var sysReqItem = "nomas-module--sysReqItem--4xshJ";
export var sysReqs = "nomas-module--sysReqs--e-zkt";
export var sysReqsText = "nomas-module--sysReqsText--n8Jt-";
export var takeMeHome = "nomas-module--takeMeHome--ihTP-";
export var techInner = "nomas-module--techInner--sqRzV";
export var techOutter = "nomas-module--techOutter--8hIPD";
export var techText = "nomas-module--techText--2F1Sm";
export var twilioImg = "nomas-module--twilioImg--NGFob";
export var volunteeringCaption = "nomas-module--volunteeringCaption--qjPuy";
export var volunteeringImg = "nomas-module--volunteeringImg--Rgmt5";